import React from 'react';
import { Container } from 'styles';
import * as S from 'styles/Home/styled';
import android from 'images/download_android.svg';
import ios from 'images/download_ios.svg';

const MobileApps = () => {
  return (
    <S.StyledSection>
      <Container>
        <S.SectionTitle>Baixe grátis o APP vagas de emprego</S.SectionTitle>
        <S.SectionParagraph>Faça o download do nosso aplicativo e procure emprego de qualquer lugar.</S.SectionParagraph>
        <S.MobileAppsContainer>
          <a href="https://play.google.com/store/apps/details?id=br.com.apps.jaya.vagas&hl=pt_BR" title="Android" target="_blank" rel="noopener noreferrer">
            <img src={android} alt="Disponível Google Play" />
          </a>
          <a href="https://apps.apple.com/br/app/vagas-de-emprego/id1230639345" title="Android" target="_blank" rel="noopener noreferrer">
            <img src={ios} alt="Baixar na App Store" />
          </a>
        </S.MobileAppsContainer>
      </Container>
    </S.StyledSection>
  )
};

export default MobileApps;